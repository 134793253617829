import React, { lazy, Suspense, useEffect } from 'react';
import {
  Route,
  Routes,
  matchPath,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import {
  useAuth,
  fetchToolsConfig,
  fetchToolInfo,
  fetchFeatureFlag,
  fetchSystemProfile,
  sharedRoutes,
  fetchUserContentRepositories,
} from '@clatter/platform';
import {
  Loader,
  Analytics,
  ProtectedRoute,
  LogoutPageView,
  SsoCallbackPage,
  AppThemeProvider,
  TrackPageviewEvent,
  appGlobalStyles,
} from '@clatter/ui';

import routes from './routes';
import { userRolesMap } from './helpers/userRoles';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

const LandingPageView = lazy(() =>
  import('./pages/LandingPage/LandingPageView'),
);

const SlideLibraryLandingPageView = lazy(() =>
  import('./pages/SlideLibraryLandingPage/SlideLibraryLandingPageView'),
);

const PresentationsPageView = lazy(() =>
  import('./pages/Presentations/PresentationsPageView'),
);

const BuildPageView = lazy(() => import('./pages/Build/BuildPageView'));

const SlideDetailsView = lazy(() =>
  import('./pages/DetailView/SlideDetails/SlideDetailsView'),
);

const DeckDetailsView = lazy(() =>
  import('./pages/DetailView/DeckDetails/DeckDetailsView'),
);

const PresentationsCreateReportView = lazy(() =>
  import('./pages/Reports/PresentationsCreate/PresentationsCreateReportView'),
);

const PresentationsDownloadsReportView = lazy(() =>
  import('./pages/Reports/PresentationsDownloads/PresentationsDownloadsReportView'),
);

const SlidesDownloadsReportView = lazy(() =>
  import('./pages/Reports/SlidesDownloads/SlidesDownloadsReportView'),
);
const DecksDownloadsReportView = lazy(() =>
  import('./pages/Reports/DecksDownloads/DecksDownloadsReportView'),
);

const GlobalStyle = createGlobalStyle`
  ${appGlobalStyles};
`;

export function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, checkSession, user } = useAuth();

  const isOnLogoutPage = !!matchPath(routes.logout, location.pathname);
  const isOnSsoCallbackPage = !!matchPath(sharedRoutes.ssoCallback, location.pathname);

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  // NOTE: Can't use 'addNotice' in the app level
  // because it is triggering infinite loop!!
  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
      dispatch(fetchUserContentRepositories({ userId: user?.id }));
    }
  }, [dispatch, isAuthenticated, user?.id]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
        <TrackPageviewEvent />
        <Analytics />

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={routes.index} element={<LandingPageView />} />
            <Route path={routes.logout} element={<LogoutPageView />} />
            <Route path={sharedRoutes.ssoCallback} element={<SsoCallbackPage />} />

            <Route
              path={routes.presentations}
              element={
                <ProtectedRoute element={PresentationsPageView} accessRoles={[userRolesMap.slideLibraryAdmin]} />
              }
            />

            <Route
              path={routes.repositories}
              element={
                <ProtectedRoute
                  element={SlideLibraryLandingPageView}
                  accessRoles={[userRolesMap.slideLibrary, userRolesMap.slideLibraryAdmin]}
                />
              }
            />

            <Route
              path={routes.editRepository}
              element={
                <ProtectedRoute
                  element={SlideLibraryLandingPageView}
                  accessRoles={[userRolesMap.slideLibraryAdmin]}
                />
              }
            />

            <Route
              path={routes.build}
              element={
                <ProtectedRoute
                  element={BuildPageView}
                  accessRoles={[userRolesMap.slideLibrary, userRolesMap.slideLibraryAdmin]}
                />
              }
            />

            <Route
              path={routes.shareSlide}
              element={
                <ProtectedRoute
                  element={SlideDetailsView}
                  accessRoles={[userRolesMap.slideLibrary, userRolesMap.slideLibraryAdmin]}
                />
              }
            />

             <Route
              path={routes.shareDeck}
              element={
                <ProtectedRoute
                  element={DeckDetailsView}
                  accessRoles={[userRolesMap.slideLibrary, userRolesMap.slideLibraryAdmin]}
                />
              }
            />

             <Route
              path={routes.reportPresentationsCreate}
              element={
                <ProtectedRoute
                  element={PresentationsCreateReportView}
                  accessRoles={[userRolesMap.slideLibraryAdmin]}
                />
              }
            />

             <Route
              path={routes.reportPresentationsDownloads}
              element={
                <ProtectedRoute
                  element={PresentationsDownloadsReportView}
                  accessRoles={[userRolesMap.slideLibraryAdmin]}
                />
              }
            />

             <Route
              path={routes.reportSlidesDownloads}
              element={
                <ProtectedRoute
                  element={SlidesDownloadsReportView}
                  accessRoles={[userRolesMap.slideLibraryAdmin]}
                />
              }
            />

             <Route
              path={routes.reportDecksDownloads}
              element={
                <ProtectedRoute
                  element={DecksDownloadsReportView}
                  accessRoles={[userRolesMap.slideLibraryAdmin]}
                />
              }
            />
          </Routes>
        </Suspense>
    </AppThemeProvider>
  );
}
export default App;
