import { configureStore } from '@reduxjs/toolkit';
import {
  PLATFORM_FEATURE_KEY,
  globalErrorHandlerMiddleware,
  platformReducers,
} from '@clatter/platform';
import { APP_UI_FEATURE_KEY, appUiReducer } from './appUi.slice';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { USERS_FEATURE_KEY, usersReducer } from "./users.slice";

export const storeConfig = {
  reducer: {
    [PLATFORM_FEATURE_KEY]: platformReducers,
    [APP_UI_FEATURE_KEY]: appUiReducer,
    [USERS_FEATURE_KEY]: usersReducer,
  },
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(globalErrorHandlerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
};

export const store = configureStore(storeConfig);
